import { completeNum, formatDate, getPlatform } from './common';
// import { COMMODITY_ZONE_USTOCK } from '../international/STATIC';

const future = {
  IH: {},
  IF: {},
  IC: {},
  CL: {
    timezone: -6,
    dayLight: true,
    trade: {
      0: [['17:00', '23:59']],
      1: [['17:00', '23:59'], ['00:00', '15:55']],
      2: [['17:00', '23:59'], ['00:00', '15:55']],
      3: [['17:00', '23:59'], ['00:00', '15:55']],
      4: [['17:00', '23:59'], ['00:00', '15:55']],
      5: [['00:00', '15:55']],
    },
  },
  HG: {
    timezone: -6,
    dayLight: true,
    trade: {
      0: [['17:00', '23:59']],
      1: [['17:00', '23:59'], ['00:00', '15:55']],
      2: [['17:00', '23:59'], ['00:00', '15:55']],
      3: [['17:00', '23:59'], ['00:00', '15:55']],
      4: [['17:00', '23:59'], ['00:00', '15:55']],
      5: [['00:00', '15:55']],
    },
  },
  SI: {
    timezone: -6,
    dayLight: true,
    trade: {
      0: [['17:00', '23:59']],
      1: [['17:00', '23:59'], ['00:00', '15:55']],
      2: [['17:00', '23:59'], ['00:00', '15:55']],
      3: [['17:00', '23:59'], ['00:00', '15:55']],
      4: [['17:00', '23:59'], ['00:00', '15:55']],
      5: [['00:00', '15:55']],
    },
  },
  GC: {
    timezone: -6,
    dayLight: true,
    trade: {
      0: [['17:00', '23:59']],
      1: [['17:00', '23:59'], ['00:00', '15:55']],
      2: [['17:00', '23:59'], ['00:00', '15:55']],
      3: [['17:00', '23:59'], ['00:00', '15:55']],
      4: [['17:00', '23:59'], ['00:00', '15:55']],
      5: [['00:00', '15:55']],
    },
  },
  NG: {
    timezone: -6,
    dayLight: true,
    trade: {
      0: [['17:00', '23:59']],
      1: [['17:00', '23:59'], ['00:00', '15:55']],
      2: [['17:00', '23:59'], ['00:00', '15:55']],
      3: [['17:00', '23:59'], ['00:00', '15:55']],
      4: [['17:00', '23:59'], ['00:00', '15:55']],
      5: [['00:00', '15:55']],
    },
  },
  CN: {
    timezone: 8,
    trade: {
      1: [['09:00', '16:25'], ['17:00', '23:59']],
      2: [['00:00', '05:10'], ['09:00', '16:25'], ['17:00', '23:59']],
      3: [['00:00', '05:10'], ['09:00', '16:25'], ['17:00', '23:59']],
      4: [['00:00', '05:10'], ['09:00', '16:25'], ['17:00', '23:59']],
      5: [['00:00', '05:10'], ['09:00', '16:25'], ['17:00', '23:59']],
      // 6: [['00:00', '05:10']],
    },
  },
  YM: {
    timezone: -5,
    dayLight: true,
    trade: {
      0: [['18:00', '23:59']],
      1: [['00:00', '16:14'], ['16:31', '17:00'], ['18:00', '23:59']],
      2: [['00:00', '16:14'], ['16:31', '17:00'], ['18:00', '23:59']],
      3: [['00:00', '16:14'], ['16:31', '17:00'], ['18:00', '23:59']],
      4: [['00:00', '16:14'], ['16:31', '17:00'], ['18:00', '23:59']],
      5: [['00:00', '16:14'], ['16:31', '17:00']],
    },
  },
  NQ: {
    timezone: -5,
    dayLight: true,
    trade: {
      0: [['18:00', '23:59']],
      1: [['00:00', '16:14'], ['16:31', '17:00'], ['18:00', '23:59']],
      2: [['00:00', '16:14'], ['16:31', '17:00'], ['18:00', '23:59']],
      3: [['00:00', '16:14'], ['16:31', '17:00'], ['18:00', '23:59']],
      4: [['00:00', '16:14'], ['16:31', '17:00'], ['18:00', '23:59']],
      5: [['00:00', '16:14'], ['16:31', '17:00']],
    },
  },
  ES: {
    timezone: -5,
    dayLight: false,
    trade: {
      0: [['18:00', '23:59']],
      1: [['00:00', '16:14'], ['16:31', '17:00'], ['18:00', '23:59']],
      2: [['00:00', '16:14'], ['16:31', '17:00'], ['18:00', '23:59']],
      3: [['00:00', '16:14'], ['16:31', '17:00'], ['18:00', '23:59']],
      4: [['00:00', '16:14'], ['16:31', '17:00'], ['18:00', '23:59']],
      5: [['00:00', '16:14'], ['16:31', '17:00']],
    },
  },
  HSI: {
    timezone: 8,
    trade: {
      1: [['09:15', '12:00'], ['13:00', '16:30'], ['17:15', '23:59']],
      2: [['00:00', '03:00'], ['09:15', '12:00'], ['13:00', '16:30'], ['17:15', '23:59']],
      3: [['00:00', '03:00'], ['09:15', '12:00'], ['13:00', '16:30'], ['17:15', '23:59']],
      4: [['00:00', '03:00'], ['09:15', '12:00'], ['13:00', '16:30'], ['17:15', '23:59']],
      5: [['00:00', '03:00'], ['09:15', '12:00'], ['13:00', '16:30'], ['17:15', '23:59']],
      // 6: [['00:00', '03:00']],
    },
  },
  NK: {
    timezone: 9,
    trade: {
      1: [['08:30', '12:59'], ['13:00', '15:30'], ['15:55', '23:59']],
      2: [['00:00', '05:45'], ['08:30', '12:59'], ['13:00', '15:30'], ['15:55', '23:59']],
      3: [['00:00', '05:45'], ['08:30', '12:59'], ['13:00', '15:30'], ['15:55', '23:59']],
      4: [['00:00', '05:45'], ['08:30', '12:59'], ['13:00', '15:30'], ['15:55', '23:59']],
      5: [['00:00', '05:45'], ['08:30', '12:59'], ['13:00', '15:30'], ['15:55', '23:59']],
      // 6: [['00:00', '05:45'],],
    },
  },
  DAX: {
    timezone: 1,
    dayLight: true,
    trade: {
      1: [['08:00', '21:55']],
      2: [['08:00', '21:55']],
      3: [['08:00', '21:55']],
      4: [['08:00', '21:55']],
      5: [['08:00', '21:55']],
    },
  },
  AUDUSD: {
    timezone: 8,
    trade: {
      1: [['00:00', '05:55'], ['06:00', '23:59']],
      2: [['00:00', '05:55'], ['06:00', '23:59']],
      3: [['00:00', '05:55'], ['06:00', '23:59']],
      4: [['00:00', '05:55'], ['06:00', '23:59']],
      5: [['00:00', '05:55'], ['06:00', '23:59']],
    },
  },
  EURUSD: {
    timezone: 8,
    trade: {
      1: [['00:00', '05:55'], ['06:00', '23:59']],
      2: [['00:00', '05:55'], ['06:00', '23:59']],
      3: [['00:00', '05:55'], ['06:00', '23:59']],
      4: [['00:00', '05:55'], ['06:00', '23:59']],
      5: [['00:00', '05:55'], ['06:00', '23:59']],
    },
  },
  GBPUSD: {
    timezone: 8,
    trade: {
      1: [['00:00', '05:55'], ['06:00', '23:59']],
      2: [['00:00', '05:55'], ['06:00', '23:59']],
      3: [['00:00', '05:55'], ['06:00', '23:59']],
      4: [['00:00', '05:55'], ['06:00', '23:59']],
      5: [['00:00', '05:55'], ['06:00', '23:59']],
    },
  },
  USDCAD: {
    timezone: 8,
    trade: {
      1: [['00:00', '05:55'], ['06:00', '23:59']],
      2: [['00:00', '05:55'], ['06:00', '23:59']],
      3: [['00:00', '05:55'], ['06:00', '23:59']],
      4: [['00:00', '05:55'], ['06:00', '23:59']],
      5: [['00:00', '05:55'], ['06:00', '23:59']],
    },
  },
  USDJPY: {
    timezone: 8,
    trade: {
      1: [['00:00', '05:55'], ['06:00', '23:59']],
      2: [['00:00', '05:55'], ['06:00', '23:59']],
      3: [['00:00', '05:55'], ['06:00', '23:59']],
      4: [['00:00', '05:55'], ['06:00', '23:59']],
      5: [['00:00', '05:55'], ['06:00', '23:59']],
    },
  },
  USDCHF: {
    timezone: 8,
    trade: {
      1: [['00:00', '05:55'], ['06:00', '23:59']],
      2: [['00:00', '05:55'], ['06:00', '23:59']],
      3: [['00:00', '05:55'], ['06:00', '23:59']],
      4: [['00:00', '05:55'], ['06:00', '23:59']],
      5: [['00:00', '05:55'], ['06:00', '23:59']],
    },
  },
  NZDUSD: {
    timezone: 8,
    trade: {
      1: [['00:00', '05:55'], ['06:00', '23:59']],
      2: [['00:00', '05:55'], ['06:00', '23:59']],
      3: [['00:00', '05:55'], ['06:00', '23:59']],
      4: [['00:00', '05:55'], ['06:00', '23:59']],
      5: [['00:00', '05:55'], ['06:00', '23:59']],
    },
  },
};

const ashare = {
  timezone: 8,
  trade: {
    1: [['09:30', '12:00'], ['13:00', '15:50']],
    2: [['09:30', '12:00'], ['13:00', '15:50']],
    3: [['09:30', '12:00'], ['13:00', '15:50']],
    4: [['09:30', '12:00'], ['13:00', '15:50']],
    5: [['09:30', '12:00'], ['13:00', '15:50']],
  },
};

function timeProcess(str) {
  if (getPlatform().includes('h5-iphone')) {
    // eslint-disable-next-line prefer-regex-literals
    return str.replace(new RegExp('-', 'gm'), '/');
  }
  return str;
}

function removeSecond(str) {
  const val = str?.valueOf();
  const l = val?.split(':');
  let t = null;
  if (l?.length === 3) {
    t = `${l[0]}:${l[1]}`;
  } else {
    t = val;
  }
  return t;
}

export default {
  p_openTime: {},
  p_tradeTime: {},
  p_total: [],
  p_all: [],
  isTrading(contract) {
    if (!contract) {
      return false;
    }

    if (contract.symbol.indexOf('-') !== -1) {
      return false;
    }

    if (this.isHoliday(contract)) {
      return false;
    }

    const now = new Date();
    if (now.getDay() === 0) {
      return false;
    }

    const nowStr = formatDate('h:i', { date: now });

    if (now.getDay() === 6) {
      const nnClose = removeSecond(contract.niteCloseTime);
      if (nnClose < '12:00' && nowStr < nnClose) {
        return false;
      }
    }

    if (!this.p_tradeTime[contract.code]) {
      this.p_tradeTime[contract.code] = this.getAllPoint(contract, true);
    }

    return this.p_tradeTime[contract.code].indexOf(nowStr) !== -1;
  },
  isOpening(contract) {
    if (!contract) {
      return false;
    }

    if (!!contract.symbol && contract.symbol.indexOf('-') !== -1) {
      return true;
    }
    const { id } = contract;
    if (!!contract.coins && !future[id] && contract.zone !== 'ustock') {
      return true;
    }
    if (this.isHoliday(contract)) {
      return false;
    }
    let detail;

    if (contract.zone === 'ustock') {
      detail = ashare;
    } else {
      detail = future[id];
    }

    if (detail === undefined) {
      return this.backup(contract);
    }

    const now = new Date();
    const nowStr = formatDate('h:i', { date: now });
    const day = this.timezone_getDay_old();
    // const day = this.timezone_getDay(detail, now);

    if (day === 0) {
      return false;
    }

    if (day === 6) {
      let nnClose = removeSecond(contract.niteCloseTime);
      nnClose = this.timezone_old(nnClose);
      if (nnClose > this.timezone_old('12:00') || nowStr > nnClose) {
        return false;
      }
    }

    // let nowStr = formatDate('h:i', {date: this.timezones(detail, now)});

    if (!this.p_openTime[id]) {
      this.p_openTime[id] = {};
    }
    if (!this.p_openTime[id][day]) {
      this.p_openTime[id][day] = this.getAllPoint_old(contract, false);
    }
    return this.p_openTime[id][day].indexOf(nowStr) !== -1;
  },

  backup(contract) {
    const now = new Date();
    if (this.timezone_getDay_old() === 0) {
      return false;
    }

    const nowStr = formatDate('h:i', { date: now });

    if (this.timezone_getDay_old() === 6) {
      let nnClose = removeSecond(contract.niteCloseTime);
      nnClose = this.timezone_old(nnClose);
      if (nnClose > this.timezone_old('12:00') || nowStr > nnClose) {
        return false;
      }
    }

    let amStart = removeSecond(contract.amOpenTime);
    let pmStart = removeSecond(contract.pmOpenTime);
    amStart = this.timezone_old(amStart);
    pmStart = this.timezone_old(pmStart);

    if (this.timezone_getDay_old() === 1) {
      if (nowStr < amStart) {
        return false;
      }
      if (amStart === this.timezone_old('00:00') && nowStr < pmStart) {
        return false;
      }
    }

    if (!this.p_openTime[contract.code]) {
      this.p_openTime[contract.code] = this.getAllPoint_old(contract, false);
    }

    return this.p_openTime[contract.code].indexOf(nowStr) !== -1;
  },

  getAllPoint(time) {
    let total = [];
    let all = [];
    let hour;
    let minute;
    let timeStr = '';
    if (this.p_total.length === 0) {
      for (let i = 0; i < 1440; i += 1) {
        hour = 0;
        minute = i % 60;
        if (i >= 60) {
          hour = (i - minute).div(60);
        }
        timeStr = `${completeNum(hour)}:${completeNum(minute)}`;
        total.push(timeStr);
      }
      this.p_total = [].concat(total);
    } else {
      total = [].concat(this.p_total);
    }

    time.forEach((e) => {
      const start = total.indexOf(e[0]);
      const end = total.indexOf(e[1]);
      all = all.concat(total.slice(start, end + 1));
    });

    return all.unique();
  },

  getAllPoint_old(contract, isTrade) {
    let amOpen;
    let amClose;
    let pmOpen;
    let pmClose;
    let nnOpen;
    let nnClose;
    let total = [];
    let all = [];
    if (isTrade) {
      amOpen = removeSecond(contract.amTradeTime);
      amClose = removeSecond(contract.amClearingTime);
      pmOpen = removeSecond(contract.pmTradeTime);
      pmClose = removeSecond(contract.pmClearingTime);
      nnOpen = removeSecond(contract.niteTradeTime);
      nnClose = removeSecond(contract.niteClearingTime);
    } else {
      amOpen = removeSecond(contract.amOpenTime);
      amClose = removeSecond(contract.amCloseTime);
      pmOpen = removeSecond(contract.pmOpenTime);
      pmClose = removeSecond(contract.pmCloseTime);
      nnOpen = removeSecond(contract.niteOpenTime);
      nnClose = removeSecond(contract.niteCloseTime);
    }
    amOpen = this.timezone_old(amOpen);
    amClose = this.timezone_old(amClose);
    pmOpen = this.timezone_old(pmOpen);
    pmClose = this.timezone_old(pmClose);
    nnOpen = this.timezone_old(nnOpen);
    nnClose = this.timezone_old(nnClose);

    let start;
    let end;
    let hour;
    let minute;
    let timeStr = '';
    if (this.p_total.length === 0) {
      for (let i = 0; i < 1440; i += 1) {
        hour = 0;
        minute = i % 60;
        if (i >= 60) {
          hour = (i - minute).div(60);
        }
        timeStr = `${completeNum(hour)}:${completeNum(minute)}`;
        total.push(timeStr);
      }
      this.p_total = [].concat(total);
    } else {
      total = [].concat(this.p_total);
    }

    if (!!amOpen && !!amClose) {
      start = total.indexOf(amOpen);
      end = total.indexOf(amClose);
      if (start > end) {
        all = all.concat(total.slice(start, total.length));
        all = all.concat(total.slice(0, end + 1));
      } else {
        all = all.concat(total.slice(start, end + 1));
      }
    }
    if (!!pmOpen && !!pmClose) {
      start = total.indexOf(pmOpen);
      end = total.indexOf(pmClose);
      if (start > end) {
        all = all.concat(total.slice(start, total.length));
        all = all.concat(total.slice(0, end + 1));
      } else {
        all = all.concat(total.slice(start, end + 1));
      }
    }
    if (!!nnOpen && !!nnClose) {
      start = total.indexOf(nnOpen);
      end = total.indexOf(nnClose);
      if (start > end) {
        all = all.concat(total.slice(start, total.length));
        all = all.concat(total.slice(0, end + 1));
      } else {
        all = all.concat(total.slice(start, end + 1));
      }
    }
    return all.unique();
  },

  timezone_old(t) {
    let time = t;
    if (!time) return t;
    const jet = (480 + new Date().getTimezoneOffset()) / 60;
    if (jet === 0) return t;
    let part = null;
    if (time.indexOf(':') !== -1) {
      const { partA, partB } = time.split(':');
      part = partB;
      time = partA;
    }
    time -= jet;
    if (time < 0) time += 24;
    if (time > 23) time -= 24;
    if (part !== null) time = `${time < 10 ? '0' : ''}${time}:${part}`;
    return time;
  },

  timezone_getDay_old() {
    const n = new Date();
    let day = n.getDay();
    const timezone = 8;
    const offset = (n.getTimezoneOffset() / 60) * -1;
    const jet = timezone - offset;
    if (jet === 0) return day;
    if (n.getHours() + jet < 0) day -= 1;
    if (n.getHours() + jet > 23) day += 1;
    if (day > 6) day = 0;
    if (day < 0) day = 6;
    return day;
  },

  timezones(d, n) {
    let { timezone } = d;
    const { dayLight } = d;
    if (!this.isDaylight() && dayLight) {
      timezone += 1;
    }
    const offset = (n.getTimezoneOffset() / 60) * -1;
    const jet = timezone - offset;
    if (jet === 0) return n;
    n.setHours(n.getHours() + jet);
    return n;
  },

  timezone_getDay(d, n) {
    let day = n.getDay();
    let { timezone } = d;
    const { dayLight } = d;
    if (!this.isDaylight() && dayLight) {
      timezone += 1;
    }
    const offset = (n.getTimezoneOffset() / 60) * -1;
    const jet = timezone - offset;
    if (jet === 0) return day;
    if (n.getHours() + jet < 0) day -= 1;
    if (n.getHours() + jet > 23) day += 1;
    if (day > 6) day = 0;
    if (day < 0) day = 6;
    return day;
  },

  isDaylight() {
    const d1 = new Date(2009, 0, 1);
    const d2 = new Date(2009, 6, 1);
    return d1.getTimezoneOffset() !== d2.getTimezoneOffset();
  },

  isHoliday(contract) {
    let result = [];
    let { holiday } = contract;
    const data = new Date();
    let timeScope;
    let arr;
    let start;
    let end;
    if (holiday) {
      holiday = holiday.split(';');
      result = holiday.filter((e) => {
        timeScope = e;
        if (timeScope !== '') {
          arr = timeScope.split(',');
          start = new Date(timeProcess(arr[0]));
          end = new Date(timeProcess(arr[1]));
          return (data.getTime() - start.getTime() > 0) && (data.getTime() - end.getTime() < 0);
        }
        return false;
      });
      return result.length > 0;
    }
    return false;
  },
};
