/* eslint-disable no-param-reassign */
import { types, flow } from 'mobx-state-tree';
import Cookies from 'js-cookie';

import restful from '../api/restful';

export const User = types.model('User', {
  email: types.string,
  username: types.string,
  bio: types.maybeNull(types.string),
  image: types.maybeNull(types.string),
});

export default types
  .model('auth', {
    // user: types.optional(types.maybe(User), types.undefined),
  })
  .actions((self) => {
    async function isTokenValid() {
      const { data } = await restful.POST('/api/user/isLogin', {});
      return data;
    }

    async function isTokenInvalid() {
      const { data } = await restful.POST('/api/sso/user_logout');
      return data;
    }

    async function getLoginDetail() {
      const res = await Promise.allSettled([restful.GET('/api/user/detail'), restful.GET('/api/mine/profile/lastKyc')]);
      const data = {};
      if (res[0].status === 'fulfilled') {
        if (res[0].value?.data?.code === 200) {
          data.user = res[0].value.data.user;
        }
      }
      if (res[1].status === 'fulfilled') {
        if (res[1].value?.data?.code === 200) {
          data.kyc = res[1].value.data.last;
        }
      }
      return data;
    }

    const checkIsLogin = flow(function* checkIsLoginFromCookie(getRate) {
      const accessToken = Cookies.get('TOKEN');
      let localCurrency = 'CNY';
      if (accessToken) {
        Cookies.set('TOKEN', accessToken);
        const { isLogin } = yield isTokenValid(accessToken);
        if (isLogin) {
          const { user, kyc } = yield getLoginDetail();
          self.user = user;
          self.kyc = kyc;
          localCurrency = user?.localCurrency || 'CNY';
        } else {
          self.user = false;
          self.kyc = false;
        }
      } else {
        self.user = false;
        self.kyc = false;
      }
      getRate(localCurrency);
    });

    const logout = flow(function* logoutProperly() {
      yield isTokenInvalid();

      Cookies.remove('TOKEN', { path: '/', domain: `.${window.location.host}` });
      self.user = false;
      self.kyc = false;
      const { pathname } = window.location;
      const { localStorage } = window;
      const lang = localStorage.getItem('lang');
      let logoutPage = '';
      if (lang === 'en-US') {
        logoutPage = '/en';
      }
      if (pathname.includes('/trade/spot/')) {
        logoutPage += '/trade/spotV2/BTCUSDT_CC';
      } else if (pathname.includes('/trade/deal/')) {
        logoutPage += '/trade/margin/BTCUSDT';
      } else if (pathname.includes('/trade/game/')) {
        logoutPage += '/trade/bitcast/BTCUSDT';
      }
      window.location.href = logoutPage || '/';
    });

    return {
      checkIsLogin,
      getLoginDetail,
      logout,
    };
  });
