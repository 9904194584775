export default {
  Home: ['BTCUSDT', 'ETHUSDT', 'LTCUSDT',
    'ETCUSDT', 'BCHUSDT', 'EOSUSDT',
    'XRPUSDT', 'ZECUSDT', 'ADAUSDT',
    'DASHUSDT', 'BCIUSDT', 'ATOMUSDT',
    'QTUMUSDT', 'ONTUSDT', 'NEOUSDT',
    'OMGUSDT', 'TRXUSDT', 'GNTUSDT',
    'WAVESUSDT', 'BSVUSDT', 'XLMUSDT',
    'NCIUSDT', 'DAXEUR', 'HSIHKD',
    'MHIHKD', 'CLUSDT', 'GCUSDT',
    'SIUSDT', 'NQUSDT', 'ECUSDT',
    'GBPUSDT', 'ADUSDT', 'CDUSDT',
    'NEUSDT'],
  Hot: ['BTCUSDT', 'DOGEUSDT', 'SHIBUSDT',
    'DOTUSDT', 'UNIUSDT', 'SUSHIUSDT'],
  Der: ['DAXEUR', 'HSIHKD', 'MHIHKD', 'CLUSDT', 'GCUSDT'],
  Best: ['BTCUSDT', 'ETHUSDT', 'ETCUSDT',
    'XRPUSDT', 'BCHUSDT', 'EOSUSDT',
    'LTCUSDT', 'DASHUSDT', 'TRXUSDT',
    'LINKUSDT'],
  Game: ['AMCETFUSDT', 'SMTETFUSDT'],
  Market: ['BTCUSDT', 'ETHUSDT', 'LTCUSDT',
    'ETCUSDT', 'FILUSDT', 'BCHUSDT',
    'EOSUSDT', 'XRPUSDT', 'ZECUSDT',
    'ADAUSDT', 'DASHUSDT', 'BCIUSDT',
    'ATOMUSDT', 'QTUMUSDT', 'ONTUSDT',
    'NEOUSDT', 'OMGUSDT', 'TRXUSDT',
    'GNTUSDT', 'WAVESUSDT', 'BSVUSDT',
    'XLMUSDT', 'NCIUSDT', 'DAX',
    'MDAX', 'HSI', 'MHI',
    'NQ', 'SI', 'GC',
    'NK', 'YM', 'NG',
    'CN', 'CL', 'ES'],
  Spot: [
    'BYDUSDT_CC', 'DASUSDT_CC', 'XLMUSDT_CC', 'LINUSDT_CC', 'MKRUSDT_CC', 'COMUSDT_CC', 'BATUSDT_CC',
    'KNCUSDT_CC', 'ZRXUSDT_CC', 'BANUSDT_CC', 'ATOUSDT_CC', 'XTZUSDT_CC', 'LENUSDT_CC', 'OMGUSDT_CC',
    'RENUSDT_CC', 'ANTUSDT_CC', 'ENJUSDT_CC', 'MANUSDT_CC', 'STOUSDT_CC', 'LUNUSDT_CC', 'SANUSDT_CC',
    'ONTUSDT_CC', 'LRCUSDT_CC', 'DOTUSDT_CC', 'YFIUSDT_CC', 'BTCUSDT_CC', 'ETHUSDT_CC', 'TRXUSDT_CC',
    'XRPUSDT_CC', 'EOSUSDT_CC', 'BCHUSDT_CC', 'LTCUSDT_CC', 'ADAUSDT_CC', 'UNIUSDT_CC', 'FILUSDT_CC',
    'ALGUSDT_CC', 'SNXUSDT_CC', 'AAVUSDT_CC', 'ETCUSDT_CC', 'ZECUSDT_CC', 'ZENUSDT_CC', 'CRVUSDT_CC',
    'SUSUSDT_CC', 'CVCUSDT_CC', 'ALPUSDT_CC', '1INUSDT_CC', 'UMAUSDT_CC', 'DOGUSDT_CC', 'KSMUSDT_CC',
    'MATUSDT_CC', 'SOLUSDT_CC', 'NEAUSDT_CC', 'EGLUSDT_CC', 'SXPUSDT_CC', 'OGNUSDT_CC', 'BNBUSDT_CC',
    'XMRUSDT_CC', 'KAVUSDT_CC', 'IRIUSDT_CC', 'FTTUSDT_CC', 'FTMUSDT_CC', 'CKBUSDT_CC', 'BTTUSDT_CC',
    'GRTUSDT_CC', 'BALUSDT_CC', 'WNXUSDT_CC', 'OCEUSDT_CC', 'SFPUSDT_CC', 'CAKUSDT_CC', 'DODUSDT_CC',
    'LITUSDT_CC', 'REEUSDT_CC', 'UNFUSDT_CC', 'XVSUSDT_CC', 'AVAUSDT_CC', 'LINKUSDT_CC', 'NANUSDT_CC',
    'SHIUSDT_CC',
  ],
  Mainstream: [
    'BTCUSDT', 'BCHUSDT', 'EOSUSDT', 'ETCUSDT', 'ETHUSDT', 'LTCUSDT',
    'XRPUSDT', 'TRXUSDT', 'ATOMUSDT', 'DOTUSDT', 'FILUSDT', 'SNXUSDT',
    'LINKUSDT', 'UNIUSDT', 'ADAUSDT', 'ZECUSDT', 'BCIUSDT', 'QTUMUSDT',
    'ONTUSDT', 'NEOUSDT', 'GNTUSDT', 'WAVESUSDT', 'BSVUSDT',
  ],
  Innovation: [
    'XLMUSDT', 'XTZUSDT', 'ZRXUSDT', 'BATUSDT', 'KNCUSDT', 'DASHUSDT',
    'AAVEUSDT', 'YFIUSDT', 'OMGUSDT', 'RENUSDT', 'MKRUSDT', 'CRVUSDT',
    'SUSHIUSDT', 'LITUSDT', 'UNFIUSDT', 'AVAXUSDT', 'NCIUSDT', 'SHIBUSDT',
    'DOGEUSDT', 'XMRUSDT',
  ],
  Derivatives: [
    'CL', 'HG', 'GC', 'SI', 'YM',
    'NQ', 'NG', 'CN', 'HSI', 'DAX',
    'NK', 'MDAX', 'MHI', 'ES',
  ],
  forex: ['AUDUSD', 'EURUSD', 'GBPUSD', 'USDCAD', 'USDJPY', 'USDCHF', 'NZDUSD'],
  ustock: ['AAPL', 'MSFT', 'AMZN', 'GOOG', 'FB',
    'NFLX', 'NVDA', 'AMD', 'PYPL', 'TSLA',
    'BYND', 'ARKK', 'DIS', 'BA', 'BABA',
    'PDD', 'NIO', 'BNTX', 'MRNA', 'PLTR',
    'TSM', 'UBER', 'PFE'],
  Contract: ['BTCUSDTWT', 'BTCUSDTWN', 'BTCUSDTMT',
    'ETHUSDTWT', 'ETHUSDTWN', 'ETHUSDTMT',
    'EOSUSDTWT', 'EOSUSDTWN', 'EOSUSDTMT'],
};
