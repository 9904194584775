/* eslint-disable no-param-reassign */
import { types } from 'mobx-state-tree';
import ReconnectingWebSocket from 'reconnecting-websocket';
import md5 from 'md5';

import { paresMessage } from '../functions/messageParser';

const urlQuerystring = () => {
  const now = Date.now();
  return `t=${now}&sign=${md5(`t=${now}&key=hello socket quote!`)}`;
};

const UrlProvider = (urls) => {
  let urlIndex = -1;
  const getUrl = () => {
    urlIndex += 1;
    return `${urls[urlIndex % urls.length]}?${urlQuerystring()}`;
  };
  return getUrl;
};

export function initialWebSocket(urls, protocols = [], options = {}) {
  const urlProvider = new UrlProvider(urls);
  const wss = new ReconnectingWebSocket(urlProvider, protocols, {
    ...options,
    minReconnectionDelay: 3000,
    connectionTimeout: 3000,
    minUptime: 3000,
  });
  return wss;
}

export default types
  .model('websocket', {})
  .actions((self) => {
    const updateQuote = (obj) => {
      if (obj === undefined) return;
      const { data, cmid } = obj;
      switch (cmid) {
        case '3000':
          break;
        case '3001':
          self.quote = data;
          break;
        case '3002':
          break;
        case '4001':
          break;
        case '4002':
          break;
        case '7001':
          if (data.type !== 1) return;
          self.depthList = data;
          break;
        case '6001':
          if (self.turnoverList === undefined) {
            self.turnoverList = [data];
          } else {
            self.turnoverList.push(data);
            self.turnoverList.sort((a, b) => b[3] - a[3]);
            if (self.turnoverList.length > 20) {
              self.turnoverList.pop();
            }
          }
          break;
        default:
          break;
      }
    };

    function sendMessage(message) {
      self.wss.send(JSON.stringify(message));
    }

    function onOpen() {
      sendMessage({ cmid: 3000 });
      sendMessage({ cmid: 3001, symbols: self.symbols });
      // console.log('WS open: ', event);
    }

    function onClose() {
      // console.log('WS close: ', event);
    }

    function onMessage(event) {
      self.updateQuote(paresMessage(JSON.parse(event.data)));
      // console.log('WS massage: ', JSON.parse(event.data));
    }

    function onError() {
      // console.log('WS error: ', event);
    }

    function subscribeOrderBook(symbol = 'BTCUSDT') {
      sendMessage({ cmid: 4001, symbols: symbol, r: '1' });
      self.wss.subcribed = symbol;
    }

    function unsubscribeOrderBook() {
      sendMessage({ cmid: 4002, symbols: self.wss.subcribed });
    }

    function connect(urls, symbols) {
      self.symbols = symbols;
      const wss = initialWebSocket(urls);
      wss.onopen = onOpen;
      wss.onclose = onClose;
      wss.onmessage = onMessage;
      wss.onerror = onError;
      wss.subscribeOrderBook = subscribeOrderBook;
      wss.unsubscribeOrderBook = unsubscribeOrderBook;

      self.wss = wss;
    }

    function disConnect() {
      self.wss.close();
    }

    return {
      disConnect,
      sendMessage,
      connect,
      updateQuote,
    };
  })
  .views((self) => ({
    getQuote(symbol) {
      return self.quote?.[symbol] || {};
    },
    getTurnoverList() {
      return self?.turnoverList || [];
    },
  }));
