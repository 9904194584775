/* eslint-disable no-param-reassign */
import { types } from 'mobx-state-tree';

import { localeMap } from '@/config/appConfig';

export default types
  .model('setting', {
    // downloadURL
  })
  .actions((self) => {
    const setDownloadURL = (downloadURL) => {
      self.downloadURL = downloadURL;
    };
    const setStorageData = (storageData) => {
      const { localStorage } = window;
      self.storageData = {
        ru: storageData.ru === null ? localStorage.getItem('ru') : storageData.ru,
        f: storageData.f === null ? localStorage.getItem('f') : storageData.f,
      };
    };
    const setColor = () => {
      const { localStorage } = window;
      self.isReverse = localStorage.getItem('isReverse') === 'true' || false;
    };

    const setLanguage = (locale) => new Promise((resolve, reject) => {
      const { localStorage } = window;
      if (localeMap[locale]) {
        if (localStorage.getItem('lang') !== localeMap[locale]) {
          localStorage.setItem('lang', localeMap[locale]);
          self.lang = localeMap[locale];
          resolve();
        }
        self.lang = localStorage.getItem('lang');
        resolve();
      }
      reject(new Error('locale not in th map'));
    });

    return {
      setDownloadURL,
      setStorageData,
      setColor,
      setLanguage,
    };
  })
  .views((self) => ({
    getDownloadURL() {
      return self?.downloadURL || {};
    },
    getLanguage() {
      return self?.lang || '';
    },
    getIsReverse() {
      return self.isReverse;
    },
    getRUF() {
      return self?.storageData || { ru: null, f: null };
    },
  }));
