/* eslint-disable no-bitwise */
/* eslint-disable no-extend-native */
const prototypeOveride = () => {
  String.prototype.hashCode = function stringToHashCode() {
    let hash = 0;
    let i;
    let chr;
    if (this.length === 0) return hash;
    for (i = 0; i < this.length; i += 1) {
      chr = this.charCodeAt(i);
      hash = ((hash << 5) - hash) + chr;
      hash |= 0; // Convert to 32bit integer
    }
    return hash;
  };
};

// prototype from emulate
// function isScientificNotation(num) {
//   var bl = false;
//   if (num == null || num == '') {
//     return bl;
//   } else {
//     bl = /^(\d+(?:\.\d+)?)(E)([\-]?\d+)$/.test(num);
//   }
//   return bl;
// }

/**
 * 科学的加法
 * @returns {*}
 */

function addition(a1, a2) {
  let r1;
  let r2;
  let arg1 = a1;
  let arg2 = a2;

  try {
    if (arg1.toString().indexOf('e') === -1) {
      r1 = arg1.toString().split('.')[1].length;
    } else {
      // eslint-disable-next-line prefer-destructuring
      r1 = arg1.toString().split('e-')[1];
    }
  } catch (e) {
    r1 = 0;
  }

  try {
    if (arg2.toString().indexOf('e') === -1) {
      r2 = arg2.toString().split('.')[1].length;
    } else {
      // eslint-disable-next-line prefer-destructuring
      r2 = arg2.toString().split('e-')[1];
    }
  } catch (e) {
    r2 = 0;
  }

  const c = Math.abs(r1 - r2);
  const m = (10 ** Math.max(r1, r2));
  if (c > 0) {
    const cm = (10 ** c);
    if (r1 > r2) {
      arg1 = arg1.toString().includes('e')
        ? Number(arg1.toString() * (10 ** r1))
        : Number(arg1.toString().replace('.', ''));
      arg2 = arg2.toString().includes('e')
        ? Number(arg2.toString() * (10 ** r2) * cm)
        : Number(arg2.toString().replace('.', '')) * cm;
    } else {
      arg1 = arg1.toString().includes('e')
        ? Number(arg1.toString() * (10 ** r1) * cm)
        : Number(arg1.toString().replace('.', '')) * cm;
      arg2 = arg2.toString().includes('e')
        ? Number(arg2.toString() * (10 ** r2))
        : Number(arg2.toString().replace('.', ''));
    }
  } else {
    arg1 = arg1.toString().includes('e')
      ? Number(arg1.toString())
      : Number(arg1.toString().replace('.', ''));
    arg2 = arg2.toString().includes('e')
      ? Number(arg2.toString())
      : Number(arg2.toString().replace('.', ''));
  }
  return (arg1 + arg2) / m;
}
Number.prototype.add = function add(arg) {
  return addition(arg, this);
};

/**
 * 科学的减法
 * @param arg
 * @returns {*}
 */
function subtraction(arg1, arg2) {
  let r1 = 0;
  let r2 = 0;
  try {
    if (arg1.toString().includes('E') || arg1.toString().includes('e')) {
      // empty
    } else {
      r1 = arg1.toString().split('.')[1].length;
    }
  } catch (e) {
    r1 = 0;
  }
  try {
    if (arg2.toString().includes('E') || arg2.toString().includes('e')) {
      // empty
    } else {
      r2 = arg2.toString().split('.')[1].length;
    }
  } catch (e) {
    r2 = 0;
  }
  const m = (10 ** Math.max(r1, r2));
  const n = r1 >= r2 ? r1 : r2;
  return Number(((arg2 * m - arg1 * m) / m).toFixed(n));
}
Number.prototype.sub = function sub(arg) {
  return subtraction(arg, this);
};

/**
 * 科学的乘法
 * @param arg
 * @returns {*}
 */
function multiplication(arg1, arg2) {
  let m = 0;
  const s1 = arg1.toString() || 0;
  const s2 = arg2.toString();

  try {
    if (arg1.toString().indexOf('e') === -1) {
      m += s1.split('.')[1].length;
    }
  } catch (e) {
    // empty
  }

  try {
    if (arg2.toString().indexOf('e') === -1) {
      m += s2.split('.')[1].length;
    }
  } catch (e) {
    // empty
  }

  const r1 = s1.toString().includes('e')
    ? Number(s1)
    : Number(s1.replace('.', ''));
  const r2 = s2.toString().includes('e')
    ? Number(s2)
    : Number(s2.replace('.', ''));
  return (r1 * r2) / (10 ** m);
}

Number.prototype.mul = function mul(arg) {
  return multiplication(arg, this);
};

/**
 * 科学的除法
 * @param arg
 * @returns {*}
 */
function division(arg1, arg2) {
  let t1 = 0;
  let t2 = 0;
  let r1;
  try {
    if (arg1.toString().includes('E') || arg1.toString().includes('e')) {
      // empty
    } else {
      t1 = arg1.toString().split('.')[1].length;
    }
  } catch (e) {
    // empty
  }

  try {
    t2 = arg2.toString().split('.')[1].length;
  } catch (e) {
    // empty
  }

  if (arg1.toString().includes('E') || arg1.toString().includes('e')) {
    r1 = Number(arg1.toString());
  } else {
    r1 = Number(arg1.toString().replace('.', ''));
  }

  const r2 = Number(arg2.toString().replace('.', ''));

  return (r1 / r2) * Number((10 ** (t2 - t1)).toFixed(Math.abs(t2 - t1)));
}

Number.prototype.div = function div(arg) {
  return division(this, arg);
};

/**
 * 千分位格式化+toFixed合体
 * @param {Number} [digit]
 * @return {string}
 */
Number.prototype.toFormat = function toFormat(digit = 2) {
  return this.toLocaleString('zh', { minimumFractionDigits: digit });
};

/**
 * 科学避险
 * @return {String}
 */
String.prototype.add = function add() {
  return this;
};
/**
 * 科学避险
 * @return {String}
 */
String.prototype.sub = function sub() {
  return this;
};
/**
 * 科学避险
 * @return {String}
 */
String.prototype.mul = function mul() {
  return this;
};
/**
 * 科学避险
 * @return {String}
 */
String.prototype.div = function div() {
  return this;
};
/**
 * 科学避险
 * @param digit
 * @return {String|string}
 */
String.prototype.toFixed = function toFixed(digit = 2) {
  // eslint-disable-next-line no-restricted-globals
  if (isNaN(Number(this))) {
    // eslint-disable-next-line no-console
    console.warn('String类型不支持toFixed方法');
    return Number(0).toFixed(digit);
  }
  return Number(this).toFixed(digit);
};

/**
 * 科学避险
 * @param digit
 * @return {String|string}
 */
String.prototype.toFormat = function toFormat(digit = 2) {
  // eslint-disable-next-line no-restricted-globals
  if (isNaN(Number(this))) {
    // eslint-disable-next-line no-console
    console.warn('String类型不支持toFormat方法');
    return Number(0).toFixed(digit);
  }
  return Number(this).toLocaleString('zh', { minimumFractionDigits: digit });
};

/**
 * 获取小数点位数
 * @returns {number}
 */
Number.prototype.floatLength = function floatLength() {
  const s = this.toString();
  if (s.indexOf('.') === -1) {
    return 0;
  }
  return s.split('.')[1].length;
};

Array.prototype.remove = function remove(val) {
  for (let i = 0; i < this.length;) {
    if (this[i] === val) {
      this.splice(i, 1);
    } else {
      i += 1;
    }
  }
};

Array.prototype.unique = function unique() {
  const r = [];
  // eslint-disable-next-line no-restricted-syntax
  for (const o of this) {
    if (!r.includes(o)) {
      r.push(o);
    }
  }
  return r;
};

Array.prototype.replace = function replace(target, value) {
  const index = this.indexOf(target);
  if (index !== -1) {
    this[index] = value;
  }
};

Array.prototype.last = function last() {
  return this[this.length - 1];
};

Array.prototype.differ = function differ(target) {
  return this.filter((e) => !target.includes(e));
};

Array.prototype.insert = function insert(target) {
  target.forEach((e) => {
    if (!this.includes(e)) this.push(e);
  });
};

Array.prototype.matrix = function matrix(size) {
  const result = [];
  for (let x = 0; x < Math.ceil(this.length / size); x += 1) {
    const start = x * size;
    const end = start + size;
    result.push(this.slice(start, end));
  }
  return result;
};

/**
 * 用于生成配置对象,将会移除配置中的undefined
 * @param data
 * @returns {*}
 */
Object.options = function options(data) {
  // eslint-disable-next-line no-restricted-syntax
  for (const o of Object.keys(data)) {
    if (data[o] === undefined) {
      // eslint-disable-next-line no-param-reassign
      delete data[o];
    }
  }
  return data;
};
// end prototype from emulate

export default prototypeOveride;
