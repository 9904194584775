import Cookies from 'js-cookie';
import restful from './restful';
import { setStorage } from '../functions/common';

const passTarget = process.env.NEXT_PUBLIC_HOST_URL;
const proxyOn = process.env.NEXT_PUBLIC_NEXT_PROXY === 'on';

export const fixUrlWithoutReload = (newUrl) => {
  const { location, history } = window;

  history.replaceState(null, document.title, newUrl || location.pathname);
};

// legacy seccsion pass
const reRouteTo = async (targetPath) => {
  if (proxyOn) {
    const token = Cookies.get('TOKEN');
    let newUrl = passTarget + targetPath;
    if (token) {
      const { data } = await restful.POST('/api/token', { token });
      newUrl += data.urlToken ? `?se=${data.urlToken}` : '';
    }
    window.location.assign(newUrl);
  } else {
    window.location.href = targetPath;
  }
};

export const checkURLSearch = () => {
  const params = new Proxy(new URLSearchParams(window.location.search), {
    get: (searchParams, prop) => searchParams.get(prop),
  });
  const { f, ru } = params;
  if (f) setStorage('f', f, 7);
  if (ru) setStorage('ru', ru, 7);
  fixUrlWithoutReload();
  return { f, ru };
};

export default reRouteTo;
