import axios from 'axios';

const defaultConfig = {
  timeout: 30000,
  withCredentials: true,
};

export class Restful {
  constructor(config = defaultConfig) {
    this.instance = axios.create(config);
  }

  REQUEST(config) {
    return this.instance.request(config);
  }

  GET(url, options = {}) {
    return this.instance.get(url, options);
  }

  POST(url, data, options = {}) {
    return this.instance.post(url, data, options);
  }
}

const defaultRequest = new Restful(defaultConfig);

defaultRequest.instance.interceptors.request.use(
  (config) => config,
  (error) => {
    // eslint-disable-next-line no-console
    console.error(error);
    Promise.reject(error);
  },
);

defaultRequest.instance.interceptors.response.use(
  (config) => config,
  (error) => {
    // eslint-disable-next-line no-console
    console.error(error);
    Promise.reject(error);
  },
);

export default defaultRequest;
