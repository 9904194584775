import moment from 'moment';
import Cookies from 'js-cookie';
import PropTypes from 'prop-types';

export function colorLuminance(hex, lum = 0) {
  // validate hex string
  let fixedhex = String(hex).replace(/[^0-9a-f]/gi, '');
  if (hex.length < 6) {
    fixedhex = hex[0] + hex[0] + hex[1] + hex[1] + hex[2] + hex[2];
  }
  // convert to decimal and change luminosity
  let rgb = '#';
  let c;
  for (let i = 0; i < 3; i += 1) {
    c = parseInt(fixedhex.substring(i * 2, i * 2 + 2), 16);
    c = Math.round(Math.min(Math.max(0, c + c * lum), 255)).toString(16);
    rgb += `00${c}`.substring(c.length);
  }

  return rgb;
}

export function arrayflatten(arr) {
  return Array.isArray(arr) ? arr.reduce((acc, item) => acc.push(...arrayflatten(item)) && acc, []) : [arr];
}

export function eqSet(xs, ys) {
  return xs.size === ys.size && [...xs].every((x) => ys.has(x));
}

export function isObject(item) {
  return item && typeof item === 'object' && !Array.isArray(item);
}

export function mergeDeep(target, ...sources) {
  if (!sources.length) return target;
  const source = sources.shift();

  if (isObject(target) && isObject(source)) {
    Object.entries(source).forEach(([key, value]) => {
      if (isObject(value)) {
        if (!target[key]) Object.assign(target, { [key]: {} });
        mergeDeep(target[key], value);
      } else {
        Object.assign(target, { [key]: value });
      }
    });
  }

  return mergeDeep(target, ...sources);
}

export function mergeArrayDeDuplication(arr1, arr2) {
  return [...new Set([...arr1, ...arr2])];
}

export function htmlDecode(input) {
  const e = document.createElement('div');
  e.innerHTML = input;
  return e.childNodes.length === 0 ? '' : e.childNodes[0].nodeValue;
}

export function getDateStr(date) {
  return moment(date).format('YYYYMMDD');
}

export function setStorage(key, value, expires = 7) {
  Cookies.set(key, value, { expires });
  window?.localStorage.setItem(key, value);
}

export function completeNum(num) {
  return num < 10 ? `0${num}` : num;
}
/**
 *
 * @param {String} format
 * @param {Object} [options]
 * @returns {*}
 */
export function formatDate(format, options = {}) {
  let { date } = options;
  const { isUTC } = options;
  /**
   * ios下不识别 ‘-’ ,使用 ‘/’ 替代
   */
  date = String(date);
  if (date.indexOf('-') !== -1) {
    date = date.replace(/-/g, '/');
  }
  /* 新旧接口兼容 旧接口返回nunber类型 新接口返回string类型 string类型时间戳无法直接转化为时间对象 */
  date = Number(date) || date;

  if (!format) { return null; }
  if (!date) {
    date = new Date();
  } else {
    date = new Date(date);
  }

  let y;
  let m;
  let d;
  let h;
  let i;
  let s;

  if (isUTC) {
    y = date.getFullYear();
    m = completeNum(date.getUTCMonth() + 1);
    d = completeNum(date.getUTCDate());
    h = completeNum(date.getUTCHours());
    i = completeNum(date.getUTCMinutes());
    s = completeNum(date.getUTCSeconds());
  } else {
    y = date.getFullYear();
    m = completeNum(date.getMonth() + 1);
    d = completeNum(date.getDate());
    h = completeNum(date.getHours());
    i = completeNum(date.getMinutes());
    s = completeNum(date.getSeconds());
  }

  return format
    .replace('y', y)
    .replace('m', m)
    .replace('d', d)
    .replace('h', h)
    .replace('i', i)
    .replace('s', s);
}

/**
 * 获取当前平台名
 * @returns {string}
 */
export function getPlatform() {
  const ua = navigator.userAgent;
  const isWindowsPhone = /(?:Windows Phone)/.test(ua);
  const isSymbian = /(?:SymbianOS)/.test(ua) || isWindowsPhone;
  const isAndroid = /(?:Android)/.test(ua);
  const isFireFox = /(?:Firefox)/.test(ua);
  // const isChrome = /(?:Chrome|CriOS)/.test(ua);
  const isTablet = /(?:iPad|PlayBook)/.test(ua) || (isAndroid && !/(?:Mobile)/.test(ua)) || (isFireFox && /(?:Tablet)/.test(ua));
  const isPhone = /(?:iPhone)/.test(ua) && !isTablet;
  const isPc = !isPhone && !isAndroid && !isSymbian;

  if (isAndroid) {
    return 'h5-android';
  }
  if (isPhone) {
    return 'h5-iphone';
  }
  if (isTablet) {
    return 'pad';
  }
  if (isPc) {
    return 'pc';
  }
  return 'h5';
}

export function openZendesk(notOpen) {
  try {
    if (window.zE) {
      if (window.zE.widget === 'classic') {
        window.zE('webWidget', 'show');
        window.zE('webWidget', 'open');
        if (notOpen) window.zE('webWidget', 'close');
      } else {
        window.zE('messenger', 'show');
        window.zE('messenger', 'open');
        if (notOpen) window.zE('messenger', 'close');
      }
    }
  } catch (e) {
    //
  }
}

export function closeZendesk() {
  try {
    if (window.zE) {
      if (window.zE.widget === 'classic') {
        window.zE('webWidget', 'hide');
      } else {
        window.zE('messenger', 'hide');
      }
    }
  } catch (e) {
    //
  }
}

export function referralRUF() {
  if (typeof window === 'undefined') return '';
  const ruParam = window.localStorage.getItem('ru');
  const fParam = window.localStorage.getItem('f');

  let paramsString = '';

  if (ruParam !== null) {
    paramsString += `ru=${ruParam}&`;
  }

  if (fParam !== null) {
    paramsString += `f=${fParam}&`;
  }

  if (paramsString.endsWith('&')) {
    paramsString = paramsString.slice(0, -1);
  }

  return paramsString ? `?${paramsString}` : paramsString;
}

export function elementPropType() {
  return typeof Element === 'undefined'
    ? PropTypes.any
    : PropTypes.instanceOf(Element);
}

// offset 70 for header
export function scrollToDiv(divId, offset = 70) {
  const targetDiv = document.getElementById(divId);
  if (targetDiv && typeof window !== 'undefined') {
    const targetPosition = targetDiv.getBoundingClientRect().top + window.scrollY - offset;
    window.scrollTo({ top: targetPosition, behavior: 'smooth' });
  }
}

export function getTradeListName(stringName, options = { imageSrc: false }) {
  try {
    if (!stringName.includes(';')) return stringName;
    const { imageSrc } = options;
    const newName = stringName.split(';');
    if (newName[1] === '' || imageSrc) return newName[0];
    if (window.localStorage.lang === 'zh-CN' || !window.location.pathname.includes('/en')) return newName[1];
    return newName[0];
  } catch (e) {
    return stringName;
  }
}

export default {
  colorLuminance,
  arrayflatten,
  isObject,
  mergeDeep,
  htmlDecode,
  getDateStr,
  setStorage,
  formatDate,
  completeNum,
  getPlatform,
  openZendesk,
  closeZendesk,
  referralRUF,
  elementPropType,
  scrollToDiv,
  getTradeListName,
};
