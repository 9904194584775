import PropTypes from 'prop-types';
import Head from 'next/head';
import { appWithTranslation } from 'next-i18next';

import prototypeOveride from '@/utils/prototype';
import { StoreProvider } from '@/utils/store';

import '@/styles/globals.scss';
import '@/styles/slick/slick.scss';
import { Toaster } from 'react-hot-toast';

function MyApp(props) {
  const { Component, pageProps } = props;
  prototypeOveride();

  return (
    <>
      <Head>
        <meta name="viewport" content="initial-scale=1, width=device-width" />
      </Head>
      <StoreProvider initailState={pageProps.initailState}>
        <Component {...pageProps} />
      </StoreProvider>
      <Toaster />
    </>
  );
}

MyApp.propTypes = {
  Component: PropTypes.elementType.isRequired,
  pageProps: PropTypes.instanceOf(Object).isRequired,
};

export default appWithTranslation(MyApp);
