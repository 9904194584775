/* eslint-disable no-param-reassign */
import { types, flow } from 'mobx-state-tree';
import restful from '../api/restful';
import rest from '../functions/rest';

export default types
  .model('symbol', {
    // groups: types.map(types.array(types.string)),
  })
  .actions((self) => {
    const setGroups = (groups) => {
      self.groups = groups;
    };

    const analyse = (data) => {
      Object.values(data).forEach((item) => {
        // Note* item.id is a different reference from emulate, might have problems in the future,
        // this._alias[this.key] = this.id; is removed as well
        item.id = item.code;
        if (item.id.indexOf(item.currency) !== -1) {
          if (item.type === 'CH') {
            const volumeMin = item.volumeMin.toString();
            if (!Number.isNaN(volumeMin) && volumeMin.includes('e-')) {
              const digit = volumeMin.split('e-');
              item.volumeDigit = digit;
            } else {
              const [, float] = volumeMin.split('.');
              item.volumeDigit = float ? float.length : 0;
            }
            item.spot = true;
            // 现货的特殊处理
            item.crypto = item.name.replace(item.currency, '');
            item.key = item.name;
          } else {
            item.futures = true;
            item.crypto = item.id.replace(item.currency, '');
          }
          item.des = `${item.crypto}/${item.currency}`;
          item.DC = true;
        } else if (/\d(L|S)_CC/g.test(item.id)) {
          item.crypto = item.id.replace(/_CC/g, '');
          item.des = `${item.crypto}/USDT`;
        } else {
          item.futures = true;
          item.crypto = '';
          item.des = item.id;
        }
        try {
          item.isOpen = rest.isOpening(item);
        } catch (e) {
          //
        }
      });
      return data;
    };

    const getList = flow(function* getTradeList(symbols) {
      const body = new FormData();
      body.append('code', symbols.join(';'));
      const res = yield restful.POST('/api/trade/commodity/tradeList', body, {
        'Content-Type': 'multipart/form-data',
      });
      if (res.data.code === 200) {
        self.tradeList = analyse(res.data.data);
        const codes = { code: {}, contractCode: {} };
        Object.values({ ...res.data.data }).forEach((symbol) => {
          Object.assign(codes.code, { [symbol.code]: symbol.contractCode });
          Object.assign(codes.contractCode, { [symbol.contractCode]: symbol.code });
        });
        self.codes = codes;
        self.currency = res.data.currency;
      }
      return { ...self.codes, currency: res.data.currency };
    });

    const getCurrencyRate = flow(function* getCurrencyRate(currency) {
      const res = yield restful.GET(`/api/home/currency/rates?des=${currency}`);
      if (res?.data?.code === 200) {
        self.rate = res.data.data;
        self.currency = currency;
      }
    });

    const setZone = (contract, zone) => {
      if (self.tradeList?.[contract]) {
        self.tradeList[contract].zone = zone;
      }
    };

    return {
      setGroups,
      setZone,
      analyse,
      getCurrencyRate,
      getList,
    };
  })
  .views((self) => ({
    getGroup(group) {
      return self.groups?.[group] || [];
    },
    getContractCode(symbol) {
      return self.tradeList?.[symbol];
    },
  }));
