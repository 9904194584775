export const quoteKeys = ['isUp', 'price', 'prev', 'buyPrice', 'buyVolume', 'sellPrice', 'sellVolume', 'max', 'min', 'volume', 'open', 'close', 'settle_price_yes', 'high_limit', 'low_limit', 'net_value' ];

export const mainSiteUrl = process.env.NEXT_PUBLIC_NEXT_PROXY === 'on' ? process.env.NEXT_PUBLIC_HOST_URL : '';

export const localeMap = {
  zh: 'zh-CN',
  en: 'en-US',
  'zh-CN': 'zh',
  'en-US': 'en',
};

export default {
  mainSiteUrl,
  quoteKeys,
  localeMap,
};
