/* eslint-disable no-console */

import { quoteKeys } from '@/config/appConfig';
// import groupList from '@/config/groupList';

// const cmidMap = {
//   from: {
//     3000: 'beat',
//     3001: 'subscribe',
//     3002: 'unsubscribe',
//     4001: 'chart',
//     4002: 'unchart',
//     7001: 'depth',
//     6001: 'turnover',
//   },
//   to: {
//     beat: 3000,
//     subscribe: 3001,
//     unsubscribe: 3002,
//     chart: 4001,
//     unchart: 4002,
//     depth: 7001,
//     turnover: 6001,
//   },
// };

const paresQuote = (dataString) => {
  const quotes = dataString.split(';').map((quoteString) => quoteString.split(','));
  // eslint-disable-next-line no-unused-vars
  const newQuotes = quotes.reduce((accQuotes, quote) => {
    const [name, ...rest] = quote;
    return Object.assign(accQuotes, {
      [name]: rest.reduce(
        (accValue, value, index) => (quoteKeys[index] ? Object.assign(accValue, { [quoteKeys[index]]: value }) : accValue),
        {},
      ),
    });
  }, {});
  return newQuotes;
};

const parseTurnoverList = (dataString) => {
  let turnoverData = [];
  try {
    turnoverData = dataString.split(',');
  } catch (e) {
    turnoverData = [];
  }

  return turnoverData;
};

const parseDepthList = (dataString) => {
  const parseData = JSON.parse(dataString);
  let asks = [];
  let bids = [];
  let accAsks = 0;
  let accBids = 0;
  try {
    asks = parseData.asks.sort((a, b) => +a[0] - +b[0]).slice(0, 20).reverse();

    bids = parseData.bids.sort((a, b) => +b[0] - +a[0]).slice(0, 20);

    asks.forEach((t) => {
      accAsks += t[1];
      // eslint-disable-next-line no-param-reassign
      t.accumulate = accAsks;
    });

    bids.forEach((t) => {
      accBids += t[1];
      // eslint-disable-next-line no-param-reassign
      t.accumulate = accBids;
    });

    asks = asks.map((t) => {
      const a1 = t[0];
      const a2 = t[1];
      const a3 = t[0].mul(t[1]);
      const a4 = t?.accumulate?.div(accAsks).mul(100);
      const a5 = t[0];
      const a6 = t[1];
      const a7 = 2;
      const a8 = 2;

      return [a1, a2, a3, a4, a5, a6, a7, a8];
    });

    bids = bids.map((t) => {
      const a1 = t[0];
      const a2 = t[1];
      const a3 = t[0].mul(t[1]);
      const a4 = t?.accumulate?.div(accBids).mul(100);
      const a5 = t[0];
      const a6 = t[1];
      const a7 = 2;
      const a8 = 2;

      return [a1, a2, a3, a4, a5, a6, a7, a8];
    });
  } catch (e) {
    console.log('error', e);
  }

  return {
    asks,
    bids,
    id: parseData.s,
    type: parseData.type,
  };
};

export const paresMessage = (message) => {
  const { cmid, data } = message;
  switch (cmid) {
    case '3000':
      break;
    case '3001':
      return { data: paresQuote(data), cmid };
    case '3002':
      break;
    case '4001':
      localStorage.setItem('testChart', data);
      break;
    case '4002':
      break;
    case '7001':
      return { data: parseDepthList(data), cmid };
    case '6001':
      return { data: parseTurnoverList(data), cmid };
    default:
      break;
  }
  return undefined;
};

export default { paresQuote };
