/* eslint-disable no-param-reassign */
import { types, flow } from 'mobx-state-tree';
import groupList from '@/config/groupList';
import restful from '../api/restful';

import { arrayflatten } from '../functions/common';
import AuthStore from './auth';
import WebSocketStore from './webSocket';
import SymbolStore from './symbol';
import SettingStore from './setting';
import { checkURLSearch } from '../api/sessionPass';

const defaultFaves = [];

function parseAppInit(initData) {
  const {
    quoteDomainList,
    group: originGroups,
    names: originNames, // brand, zones,
    zones,
    androidUrl,
    iosUrl,
    brand,
  } = initData;
  const symbols = new Set([]);
  const groups = originGroups.reduce((accGroups, group) => {
    const currentList = group.list.split(';');
    currentList.forEach(symbols.add, symbols);
    Object.assign(accGroups, { [group.name]: currentList });
    return accGroups;
  }, {});

  const quoteDomainsUrl = [...new Set(arrayflatten(quoteDomainList))];
  const quoteDomains = [...new Set(arrayflatten(quoteDomainList))].map((url) => `${url.replace('http', 'ws')}wsquote`);
  const names = originNames.reduce((acc, str) => {
    const [id, name] = str.split(':');
    return Object.assign(acc, { [id]: { name } });
  }, {});

  return {
    quoteDomainsUrl,
    quoteDomains,
    names,
    groups,
    zones,
    symbols: [...symbols],
    downloadURL: { androidUrl, iosUrl, brand },
  };
}

export default types
  .model('RootStore', {
    auth: types.optional(AuthStore, {
      user: undefined,
    }),
    symbol: types.optional(SymbolStore, {
      groups: groupList,
      currency: 'CNY',
    }),
    websocket: types.optional(WebSocketStore, {}),
    setting: types.optional(SettingStore, {}),

    initailized: types.boolean,
  })
  .actions((self) => {
    const setUp = flow(function* setUpEnviroment({
      quoteDomains, groups, downloadURL, symbols, zones, quoteDomainsUrl,
    }, useDefaultGroups) {
      self.setting.setDownloadURL(downloadURL);
      if (useDefaultGroups) {
        self.symbol.setGroups(groups);
      }
      if (JSON.stringify(self?.savedInfo?.symbols || {}) !== JSON.stringify(symbols)) {
        const { code } = yield self.symbol.getList(symbols);
        const strap = Object.values(useDefaultGroups ? groups : groupList).reduce(
          (acc, subArray) => subArray.reduce((subAcc, element) => subAcc.add(code[element]), acc),
          new Set([]),
        );

        self.websocket.connect(quoteDomains, Array.from(strap));
        self.savedInfo = {
          quoteDomains,
          groups,
          downloadURL,
          symbols,
          zones,
          quoteDomainsUrl,
        };
        self.analyseTradeList(self.savedInfo);
        self.setUpFaves();
      }
    });

    const initailizeApp = flow(function* init({ useDefaultGroups } = {}) {
      self.initailized = true;
      try {
        self.auth.checkIsLogin(self.symbol.getCurrencyRate);
        const savedInfo = JSON.parse(window?.localStorage?.getItem('initInfo'));
        if (savedInfo) yield self.setUp(savedInfo, useDefaultGroups);
        const initRes = yield restful.GET(`/api/trade/commodity/initial?_=${Date.now()}`);
        if (initRes.status !== 200) throw new Error('init failed');
        const apiInfo = parseAppInit(initRes.data);
        self.setUp(apiInfo, useDefaultGroups);
        window?.localStorage?.setItem('initInfo', JSON.stringify(apiInfo));
        const storageData = checkURLSearch();

        self.setting.setStorageData(storageData);
        self.setting.setColor();
        if (window) {
          const lang = window.localStorage.lang ? window.localStorage.lang : 'en-US';
          self.setting.lang = lang;
        }
      } catch (error) {
        // eslint-disable-next-line no-console
        console.error('root', error);
      }
    });

    const analyseTradeList = async (apiInfo) => {
      const initInfo = apiInfo;
      Object.values(initInfo.zones).forEach(({ code, list }) => {
        list.split(';').forEach((item) => {
          self.symbol.setZone(item, code);
        });
      });
    };

    const setUpFaves = () => {
      const initFaves = () => {
        const db = {
          FT: [],
          CT: [],
          B: [],
        };
        const temp = [];
        const bi = defaultFaves.filter((item) => item.includes('__'));
        bi.forEach((item) => {
          if (Object.prototype.hasOwnProperty.call(self.symbol.codes?.code, item.split('__')[0])) {
            db.B.push(item);
          }
        });
        if (self.symbol && self.symbol.tradeList) {
          Object.values(self.symbol.tradeList).forEach((value) => {
            if (defaultFaves.includes(value.code) && localStorage) {
              temp.push(value);
            }
          });
        }
        temp.forEach((item) => {
          if (item.type === 'CH') {
            db.CT.push(item.code);
          } else if (item.type === 'FT') {
            db.FT.push(item.code);
          }
        });
        const existingFaves = JSON.parse(localStorage.getItem('favor_db'));
        localStorage.setItem('favor_db', JSON.stringify({ ...existingFaves, d: db }));
      };

      if (localStorage.getItem('favor_db') === null || JSON.parse(localStorage.getItem('favor_db'))?.d === undefined) {
        initFaves();
      }
      if (self.auth.user) {
        const db = JSON.parse(localStorage.getItem('favor_db'));
        const id = self.auth.user.userId;
        if (!db[id]) {
          db[id] = db.d;
          localStorage.setItem('favor_db', JSON.stringify(db));
        }
      }
    };

    return {
      setUp,
      setUpFaves,
      analyseTradeList,
      initailizeApp,
    };
  })
  .views((self) => ({
    get isInitailized() {
      return self.initailized;
    },
    get getSavedInfo() {
      return self.savedInfo;
    },
  }));
