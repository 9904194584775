import PropTypes from 'prop-types';
import { useEffect, useMemo, useRef } from 'react';
import { Provider } from 'mobx-react';
import { applySnapshot } from 'mobx-state-tree';
import RootStore from './root';

let storeInstance;
const initailStore = {
  initailized: false,
};

export function Store(snapshot = null) {
  const storeImage = storeInstance ?? RootStore.create(initailStore);

  if (snapshot) {
    applySnapshot(storeImage, snapshot);
  }
  // For SSG and SSR always create a new store
  if (typeof window === 'undefined') return storeImage;
  // Create the store once in the client
  if (!storeInstance) storeInstance = storeImage;

  return storeInstance;
}

export function useStore(initailState) {
  const rootStore = useMemo(() => Store(initailState), [initailState]);
  return rootStore;
}

export function StoreProvider({ children, initailState }) {
  const store = useStore(initailState);
  const rendered = useRef(null);
  useEffect(() => {
    if (!rendered.current) {
      store.initailizeApp({ useDefaultGroups: true });
      rendered.current = true;
    }
  }, []);

  return <Provider store={store}>{children}</Provider>;
}

StoreProvider.propTypes = {
  children: PropTypes.element.isRequired,
  initailState: PropTypes.instanceOf(Object),
};

StoreProvider.defaultProps = {
  initailState: undefined,
};
